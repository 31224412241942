body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  padding-bottom: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav-pills .nav-link.active {
  background-color: #61dafb!important;
  font-weight: bold;
}

.footer {
  flex-shrink: 0;
  background-color: #282c34;
  padding: 10px;
  position: fixed;
  left:0;
  bottom:0;
  right:0;
}

.responsive-google-slides {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.responsive-google-slides iframe {
  border: 0;
  position: absolute;  
  top: 0;
  left: 0;
  display: block;
  max-width: 100%;
  margin: 0;
  border: 0 none;
  box-sizing: border-box;
}

html, body, #root {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
}

body {
  position: absolute;
  background-size: cover;
  background-attachment: fixed;
}

#cardBody {
  font-weight: bold;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.classCard {
  @media (max-width: 774px) {
    width: 100%;
  };
  @media (min-width: 775px) {
    width: 60%;
  }
}
